/**
 * --------------------------------------------------------------------------
 * Preloader for AJAX Requests
 * --------------------------------------------------------------------------
 * This script handles preloader functionality for AJAX requests.
 *
 * It disables the submit button and shows a spinner when an AJAX request is sent,
 * and re-enables the submit button and hides the spinner when the request is completed.
 */

(() => {
    // Select the submit button and the spinner element
    const btn = $('button[type="submit"]');
    const spinner = $('span[role="status"]');

    // Function to show preloader (disable button and show spinner)
    const showPreloader = () => {
        btn.prop('disabled', true);
        spinner.removeClass('visually-hidden');
    };

    // Function to hide preloader (enable button and hide spinner)
    const hidePreloader = () => {
        btn.prop('disabled', false);
        spinner.addClass('visually-hidden');
    };

    // Attach preloader functions to AJAX events
    $(document)
        .ajaxSend(showPreloader)      // Show preloader when AJAX request is sent
        .ajaxComplete(hidePreloader)  // Hide preloader when AJAX request is completed
        .ajaxError(hidePreloader);    // Hide preloader when AJAX request encounters an error
})();
