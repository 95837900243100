/**
 * --------------------------------------------------------------------------
 * jQuery Extensions
 * --------------------------------------------------------------------------
 * This file contains various jQuery extensions for common UI functionalities.
 *
 * These extensions provide functions for:
 * - Highlighting table columns on mouse enter and removing highlighting on mouse leave.
 * - Reloading table data with animation and providing a keyboard shortcut for refresh.
 * - Redrawing table on window resize.
 * - Populating dropdown lists asynchronously, including cascading dropdown lists.
 * - Converting string to proper case.
 * - Changing input type based on window size.
 * - Formatting date input as per requirement.
 * - Converting date to local string format.
 * - Disabling context menu (right click menu).
 * - Disabling text highlighting.
 * - Storing notification data.
 * - Displaying error message.
 */

$.fn.extend({
    // Function to highlight table column on mouse enter and remove highlighting on mouse leave
    highlightTableColumn(table) {
        $(this).on({
            mouseenter: function() {
                // Get the index of the column
                const columnIndex = table.DataTable().column(this).index();
                // Add highlight class to all cells in the column
                table.DataTable().column(columnIndex).nodes().to$().addClass('highlight');
            },
            mouseleave: function() {
                // Get the index of the column
                const columnIndex = table.DataTable().column(this).index();
                // Remove highlight class from all cells in the column
                table.DataTable().column(columnIndex).nodes().to$().removeClass('highlight');
            }
        });
    },
    // Function to reload table data with animation and keyboard shortcut
    reloadTable(table) {
        $(this).on('click', function (e) {
            const icon = $(this).find('.icon');

            // Add animation class
            icon.addClass('rotate');

            // Simulate some loading time
            setTimeout(function() {
                // Remove animation class after animation is complete
                icon.removeClass('rotate');
                // Reload table data
                table.DataTable().ajax.reload(null, true);
            }, 1000); // Change 1000 to whatever time you want the animation to take

            e.preventDefault();
        });
    },
    // Function to redraw table on window resize
    redrawTable(table) {
        $(this).on('resize', function () {
            table.DataTable().columns.adjust().draw();
        });
    },
    // Function to populate dropdown list asynchronously
    async dropdownList(url, param, valueType, el) {
        try {
            // Fetch dropdown data
            const data = await $.getJSON(url + param);
            // Populate dropdown with fetched data
            $.each(JSON.parse(data).data, function (key, value) {
                el.append(
                    $('<option>', {
                        value: valueType === 'text' ? value.name : value.id,
                        text: value.name
                    })
                );
            });
        } catch (error) {
            console.error('Error fetching dropdown data:', error);
        }
    },
    // Function to populate cascading dropdown lists asynchronously
    async cascadingDropdownList(parentUrl, childUrl, defaultOptionText, paramType, valueType, parentDropdown, childDropdown) {
        try {
            // Fetch data for parent dropdown
            const parentData = await $.getJSON(parentUrl);
            // Populate parent dropdown with fetched data
            $.each(JSON.parse(parentData).data, function (key, value) {
                parentDropdown.append(
                    $('<option>', {
                        value: valueType === 'text' ? value.name : value.id,
                        text: value.name,
                        'data-id': value.id // Append value.id as a data attribute
                    })
                );
            });

            // Handle parent dropdown change event
            parentDropdown.on('change', async function () {
                const selectedText = $(this).find('option:selected').text();
                const selectedValue = $(this).find('option:selected').data('id'); // Get value.id from the data attribute

                // Clear existing options in child dropdown
                childDropdown.empty();
                // Add a default option with dynamic text
                childDropdown.append($('<option>', {value: '', text: defaultOptionText, selected: true, disabled: true, hidden: true}));

                const param = paramType === 'text' ? selectedText : selectedValue;

                // Fetch data for child dropdown based on the selected parameter
                const childData = await $.getJSON(childUrl + '?param=' + param);
                // Populate child dropdown with fetched data
                $.each(JSON.parse(childData).data, function (key, value) {
                    childDropdown.append(
                        $('<option>', {
                            value: valueType === 'text' ? value.name : value.id,
                            text: value.name
                        })
                    );
                });
            });
        } catch (error) {
            console.error('Error fetching dropdown data:', error);
        }
    },
    // Function to convert string to proper case
    toProperCase(string) {
        return string.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
        });
    },
    // Function to change input type based on window size
    inputText2Number(input) {
        // Change input type
        const mediaQuery = window.matchMedia('(min-width: 992px)');

        const handleWindowChange = (mq) => {
            (mq.matches)
                ? input.attr('type', 'text').removeAttr('step pattern')
                : input.attr({type: 'number', step: 'any', pattern: '[0-9]*[.,]?[0-9]+'});
        };

        // Call the handler initially
        handleWindowChange(mediaQuery);

        // Attach the handler to the media query
        return mediaQuery.addEventListener('change', (e) => handleWindowChange(e.target));
    },
    // Function to format date input as per requirement
    dateFormatter(options) {
        // Default settings
        const settings = $.extend({
            dateInput: 'input[name="date_input"]',
            dateOutput: 'input[name="date"]' || 'input[name="birthdate"]'
        }, options);

        // Attach input event handler to the matched elements
        this.on('input', function () {
            const dateInput = $(settings.dateInput, this);
            const dateOutput = $(settings.dateOutput, this);
            const dateValue = dateInput.val();

            // Use regex to extract day, month, and year from the date input
            const match = dateValue.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);

            if (match) {
                const day = match[1];
                const month = match[2];
                const year = match[3];

                // Create a date object to validate the date
                const formattedDate = new Date(year + '-' + month + '-' + day);

                // Check if the formatted date is valid
                (!isNaN(formattedDate.getTime()))
                    ? dateOutput.val(year + '-' + month + '-' + day)
                    : dateOutput.val('');

            } else {
                dateOutput.val('');
            }
        });

        return this; // Enable chaining
    },
    // Function to convert date to local string format
    dateToLocalString(string) {
        const date = new Date(string); // parse the date string
        const day = date.toLocaleString('default', {day: 'numeric'}); // get day in numeric form
        const month = date.toLocaleString('default', {month: 'long'}); // get month in long form
        const year = date.toLocaleString('default', {year: 'numeric'}); // get year in numeric form
        // format the date as per requirement
        return `${day} ${month} ${year}`;
    },
    // Function to disable context menu (right click menu)
    disableContextMenu() {
        $('body').on('contextmenu', function (e) {
            e.preventDefault();
        });
    },
    // Function to disable text highlighting
    disableHighLighting() {
        $('body').on('selectstart', function (e) {
            e.preventDefault();
        });
    },
    // Function to store data in a localStorage
    saveToLocalStorage(key, value) {
        (typeof (Storage) !== 'undefined')
            ? localStorage.setItem(key, value)
            : console.log('Sorry! No Web Storage support..');
    },
    // Function to display info message
    info(msg) {
        return this.each(function() {
            $(this).find('.notif-body').html($.fn.createCallout('info', msg));
        });
    },
    // Function to display success message
    success(msg) {
        return this.each(function() {
            $(this).find('.notif-body').html($.fn.createCallout('success', msg));
        });
    },
    // Function to display error message
    error(msg) {
        return this.each(function() {
            $(this).find('.notif-body').html($.fn.createCallout('danger', msg));
        });
    },
    // Helper function to create Bootstrap callout
    createCallout(type, msg) {
        const calloutClass = `callout callout-${type}`;
        return `<div class="${calloutClass}">${msg}</div>`;
    }
});
