/**
 * --------------------------------------------------------------------------
 * DataTables Processing Indicator
 * --------------------------------------------------------------------------
 * This script handles the DataTables processing indicator using Pace.js.
 *
 * It restarts Pace.js when DataTables processing starts and stops Pace.js
 * when DataTables processing ends.
 */

(() => {
    $('#datatables__default').on('processing.dt', function (e, settings, processing) {
        // Restart Pace.js when DataTables processing starts
        (processing)
            ? Pace.restart()
            : Pace.stop();
    });
})();
